export const userColumnsButce = [{
    field: "Ana_masraf_adi",
    headerName: "Ana Masraf Adı",
    width: 200,
    headerAlign: 'center'
  },
  {
    field: "alt_maliyet_adi",
    headerName: "Alt Maliyet",
    width: 200,
    headerAlign: 'center'
  },
  {
    field: "Kirilim_Adi",
    headerName: "Kırılım",
    width: 230,
    headerAlign: 'center'
  },
  {
    field: "firma_Adi",
    headerName: "Firma",
    width: 190,
    headerAlign: 'center'
  },

  {
    field: "dokuman_tipi_adi",
    headerName: "Döküman Tipi",
    width: 190,
    headerAlign: 'center'
  },
  {
    field: "miktar",
    headerName: "Miktar",
    width: 160,
    headerAlign: 'center'
  },
  {
    field: "karakter_adi",
    headerName: "Karakter Adı",
    width: 190,
    headerAlign: 'center'
  },
  {
    field: "butce",
    headerClassName: 'butceHeader',
    headerName: "Bütçe",
    width: 160,
    headerAlign: 'center',
    editable: true
  },
  {
    field: "gerceklesen",
    headerName: "Gerçekleşen",
    width: 160,
    headerAlign: 'center'
  },
  {
    field: "odeme",
    headerName: "Ödenen",
    width: 160,
    headerAlign: 'center'
  },
  {
    field: "ek_butce",
    headerName: "Ek Bütçe",
    width: 160,
    headerAlign: 'center',
    editable: true
  },
  {
    field: "fark",
    headerName: "Fark",
    headerClassName: 'farkHeader',
    width: 160,
    headerAlign: 'center'
  },
  {
    field: "aciklama",
    headerName: "Açıklama",
    width: 160,
    headerAlign: 'center'
  },
];


export const userColumnsGerceklesen = [{
    field: "Ana_masraf_adi",
    headerName: "Ana Masraf Adı",
    width: 200,
    headerAlign: 'center'
  },
  {
    field: "alt_maliyet_adi",
    headerName: "Alt Maliyet",
    width: 200,
    headerAlign: 'center'
  },
  {
    field: "Kirilim_Adi",
    headerName: "Kırılım",
    width: 230,
    headerAlign: 'center'
  },
  {
    field: "butce",
    headerClassName: 'butceHeader',
    headerName: "Bütçe",
    width: 160,
    headerAlign: 'center',
  },
  {
    field: "ek_butce",
    headerName: "Ek Bütçe",
    width: 160,
    headerAlign: 'center'
  },
  {
    field: "gerceklesen",
    headerName: "Gerçekleşen",
    headerClassName: 'gerceklesenHeader',
    width: 160,
    headerAlign: 'center',
    editable: true,
  },
];

export const userColumnsOdeme = [{
  field: "Ana_masraf_adi",
  headerName: "Ana Masraf Adı",
  width: 200,
  headerAlign: 'center'
},
{
  field: "alt_maliyet_adi",
  headerName: "Alt Maliyet",
  width: 200,
  headerAlign: 'center'
},
{
  field: "Kirilim_Adi",
  headerName: "Kırılım",
  width: 230,
  headerAlign: 'center'
},
{
  field: "butce",
  headerClassName: 'butceHeader',
  headerName: "Bütçe",
  width: 160,
  headerAlign: 'center',
},
{
  field: "ek_butce",
  headerName: "Ek Bütçe",
  width: 160,
  headerAlign: 'center'
},
{
  field: "gerceklesen",
  headerName: "Gerçekleşen",
  width: 160,
  headerAlign: 'center'
},
{
  field: "odeme",
  headerName: "Ödeme",
  headerClassName: 'odemeHeader',
  width: 160,
  headerAlign: 'center',
  editable: true
},
{
  field: "fark",
  headerName: "Fark",
  headerClassName: 'farkHeader',
  width: 160,
  headerAlign: 'center'
},
];