import Home from "./pages/home/Home";
import ButceIslemleri from "./pages/butce-islemleri/ButceIslemleri"
import ButceGenelRapor from "./pages/butce-genel-rapor/ButceGenelRapor";
import ProjeEkle from "./pages/proje-ekle/ProjeEkle";
import AnaMaliyetEkle from './pages/ana-maliyet-ekle/AnaMaliyetEkle'
import AltMaliyetEkle from './pages/alt-maliyet-ekle/AltMaliyetEkle'
import AltMaliyetKirilimEkle from './pages/alt-maliyet-kirilim-ekle/AltMaliyetKirilimEkle'
import ProjeTipiEkle from './pages/proje-tipi-ekle/ProjeTipiEkle'
import DokumanTipiEkle from './pages/dokuman-tipi-ekle/DokumanTipiEkle'
import FirmaEkle from './pages/firma-ekle/FirmaEkle'
import GerceklesenRapor from "./pages/gerceklesen-rapor/GerceklesenRapor";
import GerceklesenVeriGirisi from "./pages/gerceklesen-veri-girisi/GerceklesenVeriGirisi";
import OdemeVeriGirisi from "./pages/odeme-veri-girisi/OdemeVeriGirisi";
import FarklarRapor from "./pages/farklar-rapor/FarklarRapor";
import OdemeRapor from "./pages/odeme-rapor/OdemeRapor";
import AvansKapama from "./pages/avans-kapama/AvansKapama";
import Login from "./pages/login/Login";
import AvansRapor from "./pages/avans-rapor/AvansRapor";


import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/">
                <Route path="butce-islemleri" element={<ButceIslemleri />} />
                <Route path="gerceklesen-veri-girisi" element={<GerceklesenVeriGirisi />} />
                <Route path="odeme-veri-girisi" element={<OdemeVeriGirisi />} />
                <Route path="avans-kapama" element={<AvansKapama />} />




                <Route path="butce-genel-rapor" element={<ButceGenelRapor />} />
                <Route path="gerceklesen-rapor" element={<GerceklesenRapor />} />
                <Route path="farklar-rapor" element={<FarklarRapor />} />
                <Route path="odeme-rapor" element={<OdemeRapor />} />
                <Route path="avans-rapor" element={<AvansRapor />} />

                {/* Ekleme Menüsü */}
                <Route path="proje-ekle" element={<ProjeEkle />} />
                <Route path="ana-maliyet-ekle" element={<AnaMaliyetEkle />} />
                <Route path="alt-maliyet-ekle" element={<AltMaliyetEkle />} />
                <Route path="alt-maliyet-kirilim-ekle" element={<AltMaliyetKirilimEkle />} />
                <Route path="proje-tipi-ekle" element={<ProjeTipiEkle />} />
                <Route path="dokuman-tipi-ekle" element={<DokumanTipiEkle />} />
                <Route path="firma-ekle" element={<FirmaEkle />} />

                  

                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                {/* <Route path="login" element={<Login />} />

                <Route path="users" >
                  <Route index element={<List />} />
                  <Route path=":userId" element={<Single />} />
                  <Route path="new" element={<New inputs = {userInputs} title="Add New User"/>} />
                </Route> */}

                {/* <Route path="products" >
                  <Route index element={<List />} />
                  <Route path=":productId" element={<Single />} />
                  <Route path="new" element={<New inputs = {productInputs} title="Add New Product"/>} />
                </Route> */}
                
              </Route>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
