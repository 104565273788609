import { useState, useRef } from "react";
import "./table.scss"
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { userColumnsButce, userColumnsGerceklesen, userColumnsOdeme } from "../../datatablesource";
import axios from "axios";
import { MutatingDots } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";


const List = (props) => {

  const [newRow, setNewRows]  = useState([]);

  const [loading, setLoading] = useState(false);

  let { data, columnType , seciliProje, seciliBolum } = props;


  console.log(data);
  let columns
  switch (columnType) {
    case "gerceklesen":
      columns = userColumnsGerceklesen
      break;
    case "butce":
      columns = userColumnsButce
      break;
    case "odeme":
      columns = userColumnsOdeme
      break;
    default:
      columns = userColumnsButce
      break;
  }


  const actionColumn = [{
    field: "duzenle",
    headerName: "Düzenle",
    width: 130,
    headerAlign: 'center',
    renderCell: (params) => {
        return (
            <div className="cellAction">
                <div className="saveButton" onClick={(oEvent) => onClick(oEvent, params.row)}>Kaydet</div>
            </div>
        )
    }
}]

const updatedData = data.map((item, index) => ({
  ...item,
  rowID: index + 1, // Her veri öğesi için benzersiz bir id oluşturuldu
}));

  

const onClick = (oEvent, rowData) => {
    
  if (columnType === "butce") { // Bütçe tablosunda kaydete basıldıysa seçili satır update olacak.
    
    axios.post('http://localhost:5001/api/post-kalem-guncelle', {
      id : rowData.id,
      butce: rowData.butce ,
      ekButce: rowData.ek_butce,
      type : "butce"
    })
    .then(function (response) {
      if (response.status === 200) {
        toast.success("Başarıyla kayıt atıldı!", {
          position: toast.POSITION.BOTTOM_CENTER
        })
       }
    })
    .catch(function (error) {
      console.error(error);
    });
    
  } else if(columnType === "gerceklesen") { //Gerçekleşen tablosunda kaydete basıldıysa seçili satır update olacak.
    
    axios.post('http://localhost:5001/api/post-kalem-guncelle', {
      id : rowData.id,
      gerceklesen : rowData.gerceklesen,
      type : "gerceklesen"
    })
    .then(function (response) {
      if (response.status === 200) {
        toast.success("Başarıyla kayıt atıldı!", {
          position: toast.POSITION.BOTTOM_CENTER
        })
       }
    })
    .catch(function (error) {
      console.error(error);
    });

  } else { //Ödenen tablosunda kaydete basıldıysa seçili satır update olacak.
    
    axios.post('http://localhost:5001/api/post-kalem-guncelle', {
      id : rowData.id,
      odeme : rowData.odeme,
      type : "odeme"
    })
    .then(function (response) {
      if (response.status === 200) {
        toast.success("Başarıyla kayıt atıldı!", {
          position: toast.POSITION.BOTTOM_CENTER
        })
       }
    })
    .catch(function (error) {
      console.error(error);
    });

  }
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: 'tablo',
          utf8WithBom: true,
          delimiter: ';',
          includeHeaders: true
        }}
      />
    </GridToolbarContainer>
  );
}

  return (
    <div>
     {loading ? (
            <MutatingDots 
            height="100"
            width="100"
            color="#4e73df"
            secondaryColor= '#4e73df'
            radius='12.5'
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />
      ) : (
    <div className="datatable"> 
      <DataGrid
            id="dataGridTable"
            className="centered-rows-table"
            editMode="row"
            rows={updatedData}
            columns={columns}
            pageSize={9}
            rowsPerPageOptions={[9]}
            getRowId={(row) => row.rowID}
            slots={{
              toolbar: CustomToolbar,
            }}
            sx={{
              boxShadow: 2,
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}
          />
    </div>)}
    </div>
  );
}

export default List