import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import './firmaekle.scss'


function FirmaEkle() {
  return (
    <div className="firmaEkle">
    <Sidebar/>
    <div className="firmaEkleContainer">
    <Navbar/>
    </div>
  </div>
  )
}

export default FirmaEkle