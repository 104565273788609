import React from 'react'
import './dokumantipiekle.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'

function DokumanTipiEkle() {
  return (
    <div className="dokumanTipiEkle">
    <Sidebar/>
    <div className="dokumanTipiEkleContainer">
    <Navbar/>
    </div>
  </div>
  )
}

export default DokumanTipiEkle