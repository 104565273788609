import { useState } from "react";
import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddchartIcon from '@mui/icons-material/Addchart';
import logo from '../../../src/assets/logo.png'
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import {
    BrowserRouter,
    Routes,
    Route,
    NavLink,
    Link
} from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";

const Sidebar = () => {

    const { logout } = useContext(AuthContext)

    const [showSidebar, setShowSidebar] = useState(false);

    const handleSidebarToggle = () => {
      setShowSidebar(!showSidebar);
    };

  return (
    
    <div className="sidebar">
        <div className="top">
            {/* <span className="logo">NTC Medya</span> */}
            <img src={logo} alt="logo" className="logo" />
        </div>
        {/* <hr /> */}
        <div className="center">
            <ul>
                <p className="title">DASHBOARD</p>
                <li title="Dashboard">
                    <DashboardIcon className="icon" />
                    <span><Link className="nounderline" to="/">Anasayfa</Link></span>
                </li>
                <p className="title">BÜTÇE İŞLEMLERİ</p>
                <li title="Bütçe İşlemleri">
                    <MonetizationOnIcon className="icon"/>
                    <span><Link className="nounderline" to="/butce-islemleri">Bütçe İşlemleri</Link></span>
                </li>
                <li title="Gerçekleşen Veri Girişi">
                    <AddchartIcon className="icon"/>
                    <span><Link className="nounderline" to="/gerceklesen-veri-girisi">Gerçekleşen Veri Girişi</Link></span>
                </li>
                <li title="Ödeme Veri Girişi">
                    <PriceCheckIcon className="icon"/>
                    <span><Link className="nounderline" to="/odeme-veri-girisi">Ödeme Veri Girişi</Link></span>
                </li>
                <li title="Avans Kapama">
                    <RequestQuoteIcon className="icon"/>
                    <span><Link className="nounderline" to="/avans-kapama">Avans Kapama</Link></span>
                </li>
                <li title="Bölüm Kopyalama">
                    <FileCopyIcon className="icon"/>
                    <span>Bölüm Kopyalama</span>
                </li>
                <p className="title">EKLEME MENÜSÜ</p>
                <li title="Proje Ekle"> 
                    <AddIcon className="icon"/>
                    <span><Link className="nounderline" to="/proje-ekle">Proje Ekle</Link></span>
                </li >
                <li title="Ana Maliyet Ekle">
                    <AddIcon className="icon"/>
                    <span><Link className="nounderline" to="/ana-maliyet-ekle">Ana Maliyet Ekle</Link></span>
                </li>
                <li title="Alt Maliyet Ekle">
                    <AddIcon className="icon"/>
                    <span><Link className="nounderline" to="/alt-maliyet-ekle">Alt Maliyet Ekle</Link></span>
                </li>
                <li title="Alt Maliyet Kırılım Ekle">
                    <AddIcon className="icon"/>
                    <span><Link className="nounderline" to="/alt-maliyet-kirilim-ekle">Alt Maliyet Kırılım Ekle</Link></span>
                </li>
                <li title="Proje Tipi Ekle">
                    <AddIcon className="icon"/>
                    <span><Link className="nounderline" to="/proje-tipi-ekle">Proje Tipi Ekle</Link></span>
                </li>
                <li title="Döküman Tipi Ekle">
                    <AddIcon className="icon"/>
                    <span><Link className="nounderline" to="/dokuman-tipi-ekle">Döküman Tipi Ekle</Link></span>
                </li>
                <li title="Firma Ekle">
                    <AddIcon className="icon"/>
                    <span><Link className="nounderline" to="/firma-ekle">Firma Ekle</Link></span>
                </li>
                <p className="title">RAPORLAR</p>
                <li title="Bütçe Genel Rapor">
                    <AssessmentIcon className="icon"/>
                    <span><Link className="nounderline" to="/butce-genel-rapor">Bütçe Genel Rapor</Link></span>
                </li>
                <li title="Gerçekleşen Rapor">
                    <AssessmentIcon className="icon"/>
                    <span><Link className="nounderline" to="/gerceklesen-rapor">Gerçekleşen Rapor</Link></span>
                </li>
                <li title="Farklar Rapor">
                    <AssessmentIcon className="icon"/>
                    <span><Link className="nounderline" to="/farklar-rapor">Farklar Rapor</Link></span>
                </li>
                <li title="Ödeme Rapor">
                    <AssessmentIcon className="icon"/>
                    <span><Link className="nounderline" to="/odeme-rapor">Ödeme Rapor</Link></span>
                </li>
                <li title="Avans Rapor">
                    <AssessmentIcon className="icon"/>
                    <span><Link className="nounderline" to="/avans-rapor">Avans Rapor</Link></span>
                </li>
                <p className="title">USER</p>
                <li title="Profil">
                    <AccountCircleIcon className="icon"/>
                    <span>Profil</span>
                </li>
                <li title="Ayarlar">
                    <SettingsIcon className="icon"/>
                    <span>Ayarlar</span>
                </li>
                <li title="Çıkış">
                    <LogoutIcon className="icon"/>
                    <span onClick={logout}><Link className="nounderline" to="/login">Oturumu Kapat</Link></span>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Sidebar
