import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import './altmaliyetekle.scss'

function AltMaliyetEkle() {
  return (
    <div className="altMaliyetEkle">
    <Sidebar/>
    <div className="altMaliyetEkleContainer">
    <Navbar/>
    </div>
  </div>
  )
}

export default AltMaliyetEkle