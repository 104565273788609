import React, {useState, useEffect} from 'react'
import axios from 'axios'
import './avanskapama.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { FormControl,FormControlLabel,RadioGroup, Radio,FormLabel, TextField, Modal, Box, Button, Select, MenuItem, InputLabel, Grid, DatePicker } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import DoneIcon from '@mui/icons-material/Done';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';


function AvansKapama() {
    
  const [projeler, setProjeler] = useState([]);
  const [seciliProje, setSeciliProje] = useState('');
  const [seciliProjeID, setSeciliProjeID] = useState('')

  const [bolumler, setBolumler] = useState([])
  const [seciliBolum, setSeciliBolum] = useState('')
  const [seciliBolumID, setSeciliBolumID] = useState('')

  const [firmalar, setFirmalar] = useState([])
  const [dokumanTipleri, setDokumanTipleri] = useState([])

  const [selectedDate, setSelectedDate] = useState(null);

  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    fetchData()
  }, []) 

  const fetchData = async () => {
    try {
      const projeResponse = axios.get('http://localhost:5001/api/get-projeler');
      const bolumResponse = axios.get('http://localhost:5001/api/get-bolumler');
      const firmaResponse = axios.get('http://localhost:5001/api/get-firma');
      const dokumanTipiResponse = axios.get('http://localhost:5001/api/get-dokuman-tipi');
  
      const [projeler, bolumler, firmalar, dokumanTipleri] = await Promise.all([projeResponse, bolumResponse, firmaResponse, dokumanTipiResponse]);


      setProjeler(projeler.data)
      setBolumler(bolumler.data)
      setFirmalar(firmalar.data)
      setDokumanTipleri(dokumanTipleri.data)

    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeProje = (event) => {
    const selectedValue = event.target.value;
    const selectedItem = projeler.find((item) => item.proje_adi === selectedValue);
    const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
    setSeciliProjeID(selectedKey)
    setSeciliProje(selectedValue)

  };

  const handleChangeBolum = (event) => {

    const selectedValue = event.target.value;
    const selectedItem = bolumler.find((item) => item.bolum_no_text === selectedValue);
    const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
    setSeciliBolumID(selectedKey)
    setSeciliBolum(selectedValue)
  }

  const handleFieldChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  
  const textFieldStyle = {
    marginBottom: '10px', // TextField'ları birbirinden ayıran boşluk
  };



    // Form Verileri
    const [formData, setFormData] = useState({
        seciliProjeForm: '',
        seciliBolumForm: '',
        seciliEkipForm : '',
        seciliFirmaForm: '',
        seciliDokumanTipiForm: '',
        seciliTarihForm : '',
        seciliBelgeNoForm: '',
        seciliBelgesizNoForm: '',
        seciliKDVOranForm : '',
        seciliToplamKDVForm : '',
        seciliKDVsizTutarForm : '',
        seciliToplamGiderForm: '',
        seciliAciklamaForm: '',

      });

      const onClickKaydet = () => {

        let postObj = {
          projeID: '',
          bolumID: '',
          firmaID: '',
          dokumanTipiID: '',
          belgeNo: 0,
          belgesiz: 0,
          aciklama: '',
          tarih : '',
          KDVOran : 0,
          toplamGider : 0,
          toplamKDV : 0,
          KDVsizTutar : 0,
          ekipKirilimID : 0

        }

        
        projeler.forEach((item) => {
          if(item.proje_adi === formData.seciliProjeForm){
           postObj.projeID = parseInt(item.id)
          }
        })
    
        
        bolumler.forEach((item) => {
          if(item.bolum_no_text === formData.seciliBolumForm){
           postObj.bolumID = parseInt(item.bolum_no_int)
          }
        })
    
        firmalar.forEach((item) => {
          if(item.firma_Adi === formData.seciliFirmaForm){
           postObj.firmaID = item.id
          }
        })
    
        dokumanTipleri.forEach((item) => {
          if(item.dokuman_tipi_adi === formData.seciliDokumanTipiForm){
           postObj.dokumanTipiID = item.id
          }
        })

        
      
        postObj.aciklama = formData.seciliAciklamaForm
        postObj.belgeNo = parseInt(formData.seciliBelgeNoForm)
        postObj.belgesiz = parseInt(formData.seciliBelgesizNoForm)
        postObj.ekipKirilimID = parseInt(formData.seciliEkipForm)
        postObj.tarih = formData.seciliTarihForm
        postObj.KDVOran = parseInt(formData.seciliKDVOranForm)
        postObj.toplamGider = parseFloat(formData.seciliToplamGiderForm)
        postObj.toplamKDV = parseFloat(formData.seciliToplamKDVForm)
        postObj.KDVsizTutar = parseInt(formData.seciliKDVsizTutarForm)

        for (let key in postObj) {
          if (postObj.hasOwnProperty(key)) {
            if (postObj[key] === null || postObj[key] === '') {
              return toast.error("Lütfen boş alanları doldurunuz!", {
                position: toast.POSITION.BOTTOM_CENTER
              })
            }
          }
        }
      

        setLoading(true)

        //Öncelikle SELECT atılacak ilgili proje ve bölümde kayıt varsa UPDATE, eğer kayıt yoksa INSERT sorgusu çalışacak.

        const getData = async () => {
          setLoading(true);
          try {
            debugger
            const responseSelect = await axios.get('http://localhost:5001/api/get-avans-kapama', { 
              params: {
                projeID : postObj.projeID,
                bolumID : postObj.bolumID
                } 
            });
            
            if (responseSelect.data.length > 0) { // Bu kayıtta daha önce avans kapama varsa UPDATE atılır.
              debugger
              const response = await axios.post('http://localhost:5001/api/post-avans-kapama', { 
                id: responseSelect.data[0].id,
                butce : responseSelect.data[0].butce,
                type : "update",
                projeID : postObj.projeID,
                bolumID : postObj.bolumID,
                firmaID : postObj.firmaID,
                dokumanTipiID : postObj.dokumanTipiID,
                toplamGider : postObj.toplamGider,
                aciklama : postObj.aciklama,
                belgeNo : postObj.belgeNo,
                tarih : postObj.tarih,
                ekipKirilimID : postObj.ekipKirilimID,
                belgesiz : postObj.belgesiz,
                KDVOran : postObj.KDVOran,
                KDVsizTutar : postObj.KDVsizTutar,
              });

              if (response.status === 200) {
                toast.success("Kayıt başarıyla güncellendi!", {
                  position: toast.POSITION.BOTTOM_CENTER
                })
              }

            }else{
              const response = await axios.post('http://localhost:5001/api/post-avans-kapama', {
                  projeID : postObj.projeID,
                  bolumID : postObj.bolumID,
                  firmaID : postObj.firmaID,
                  dokumanTipiID : postObj.dokumanTipiID,
                  toplamGider : postObj.toplamGider,
                  aciklama : postObj.aciklama,
                  belgeNo : postObj.belgeNo,
                  tarih : postObj.tarih,
                  ekipKirilimID : postObj.ekipKirilimID,
                  belgesiz : postObj.belgesiz,
                  KDVOran : postObj.KDVOran,
                  KDVsizTutar : postObj.KDVsizTutar,
                  type : "insert",
                  
              });

              
              if (response.status === 200) {
                toast.success("Kayıt başarıyla eklendi!", {
                  position: toast.POSITION.BOTTOM_CENTER
                })
              }
              
            }

          } catch (error) {
            console.error(error);

          } finally {
            setLoading(false);

          }
        };

        getData()
   
      } 


      
      const onChangeToplamGider = (field, value) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field]: parseInt(value),
        }));
      
        if (!formData.seciliToplamKDVForm) {
          return;
        }
      
        let KDVTutari = (parseInt(value) * formData.seciliToplamKDVForm) / 100; // KDV Tutarı
        let KDVSizTutar = parseInt(value) - KDVTutari;
      
        setFormData((prevFormData) => ({
          ...prevFormData,
          seciliKDVsizTutarForm: parseFloat(KDVSizTutar),
        }));
      };
      
      const onChangeToplamKDV = (field, value) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field]: parseInt(value),
        }));
      
        if (!formData.seciliToplamGiderForm) {
          return;
        }
      
        let KDVTutari = (formData.seciliToplamGiderForm * parseInt(value)) / 100; // KDV Tutarı
        let KDVSizTutar = formData.seciliToplamGiderForm - KDVTutari;
      
        setFormData((prevFormData) => ({
          ...prevFormData,
          seciliKDVsizTutarForm: parseFloat(KDVSizTutar),
        }));
      };
      



  return (
    <div className="avansKapama">
    <Sidebar/>
    <div className="avansKapamaContainer">
    <Navbar/>
    <div className="form">
    <Grid container spacing={2}>
                {/* İlk sütun */}
                <Grid item xs={6}>
                    <InputLabel id="projeSec">Proje Seç</InputLabel>
                    <Select
                    required
                    size="small"
                    fullWidth 
                    style={textFieldStyle}
                    labelId="demo-select-small-label"
                    id="projeSec"
                    value={formData.seciliProjeForm}
                    onChange={(e) => handleFieldChange('seciliProjeForm', e.target.value)}>
                      {projeler.map((item, key) => (
                      <MenuItem key={item.id} value={item.proje_adi}>{item.proje_adi}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="bolumSec">Bölüm Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="bolumSec"
                    value={formData.seciliBolumForm}
                    onChange={(e) => handleFieldChange('seciliBolumForm', e.target.value)}>
                      {bolumler.map((item, key) => (
                      <MenuItem key={item.id} value={item.bolum_no_text}>{item.bolum_no_text}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="bolumSec">Ekip Seç</InputLabel>
                  <Select
                    size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="ekipSec"
                    value={formData.seciliKirilimID}
                    onChange={(e) => handleFieldChange('seciliEkipForm', e.target.value)}>
                    <MenuItem key="12" value="12">Kostüm</MenuItem>
                    <MenuItem key="13" value="13">Sanat</MenuItem>
                    <MenuItem key="14" value="14">Prodüksiyon</MenuItem>
                  </Select>
                  <InputLabel id="firmaSec">Firma Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="firmaSec"
                    value={formData.seciliFirmaForm}
                    onChange={(e) => handleFieldChange('seciliFirmaForm', e.target.value)}>
                      {firmalar.map((item, key) => (
                      <MenuItem key={item.id} value={item.firma_Adi}>{item.firma_Adi}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="dokumanTipiSec">Döküman Tipi Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="dokumanTipiSec"
                    value={formData.seciliDokumanTipiForm}
                    onChange={(e) => handleFieldChange('seciliDokumanTipiForm', e.target.value)}>
                      {dokumanTipleri.map((item, key) => (
                      <MenuItem key={item.id} value={item.dokuman_tipi_adi}>{item.dokuman_tipi_adi}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="tarih">Tarih</InputLabel>
                  <TextField
                    id="date"
                    type="date"
                    size="small"
                    fullWidth
                    onChange={(e) => handleFieldChange('seciliTarihForm', e.target.value)}
                    style={textFieldStyle}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>

                {/* İkinci sütun */}
                <Grid item xs={6}>
           
                  <InputLabel id="belgeNo">Belge No</InputLabel>
                  <TextField onChange={(e) => handleFieldChange('seciliBelgeNoForm', e.target.value)} size="small" type="number" fullWidth style={textFieldStyle} />
                  {/* <InputLabel id="belgesiz">Belgesiz</InputLabel>
                  <TextField onChange={(e) => handleFieldChange('seciliBelgesizNoForm', e.target.value)} size="small" type="number" fullWidth style={textFieldStyle} />
                  <InputLabel id="belgeNo">KDV Oranı (%)</InputLabel>
                  <RadioGroup
                    fullWidth
                    row
                    style={textFieldStyle}
                    size="small"
                    name="radio-buttons-group"
                    onChange={(e) => handleFieldChange('seciliKDVOranForm', e.target.value)}
                    
                >
                    <FormControlLabel value="0" control={<Radio />} label="% 0" />
                    <FormControlLabel value="1" control={<Radio />} label="% 1" />
                    <FormControlLabel value="8" control={<Radio />} label="% 8" />
                    <FormControlLabel value="18" control={<Radio />} label="% 18" />
                </RadioGroup> */}

                  <InputLabel >Toplam KDV</InputLabel>
                  <TextField onChange={(e) => onChangeToplamKDV('seciliToplamKDVForm', e.target.value)} type="number" size="small" fullWidth style={textFieldStyle} />

                  <InputLabel >Toplam Gider</InputLabel>
                  <OutlinedInput startAdornment={<InputAdornment position="start">₺</InputAdornment>} onChange={(e) => onChangeToplamGider('seciliToplamGiderForm', e.target.value)} type="number" size="small" fullWidth style={textFieldStyle} />

                  <InputLabel >KDV'siz Tutar</InputLabel>
                  <OutlinedInput startAdornment={<InputAdornment position="start">₺</InputAdornment>} disabled variant='filled' value={formData.seciliKDVsizTutarForm} type="number" size="small" fullWidth style={textFieldStyle} />

                </Grid>
                <Grid item xs={12}>
                <InputLabel id="aciklama">Açıklama</InputLabel>
                  <TextField multiline="true"  onChange={(e) => handleFieldChange('seciliAciklamaForm', e.target.value)} size="small" fullWidth style={textFieldStyle} />
                </Grid>
              </Grid>
              <div className='button'>
                <Button multiline="true" onClick={onClickKaydet} variant="contained" color="success" endIcon={<DoneIcon />}>
                  Kaydet
                </Button>
              </div>
    </div>
    </div>
        <ToastContainer autoClose={1500}/>
  </div>
  )
}

export default AvansKapama