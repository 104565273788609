import {useState, useEffect} from 'react'
import "./home.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Widget from '../../components/widget/Widget'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LineChart, Line, ResponsiveContainer } from 'recharts';

import {MutatingDots} from 'react-loader-spinner'
import axios from 'axios'
import { FormControl, Button, Select, MenuItem, InputLabel, Grid } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Home = () => {

    const [rapor, setRapor] = useState([])
    const [loading, setLoading] = useState(false);

        
    const [projeler, setProjeler] = useState([]);
    const [seciliProje, setSeciliProje] = useState('');
    const [seciliProjeID, setSeciliProjeID] = useState('')

    const [bolumler, setBolumler] = useState([])
    const [seciliBolum, setSeciliBolum] = useState('')
    const [seciliBolumID, setSeciliBolumID] = useState('')
    
    const [butce, setButce] = useState([])
  
    
      useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
            const projeResponse = axios.get('http://localhost:5001/api/get-projeler');
            const bolumResponse = axios.get('http://localhost:5001/api/get-bolumler');

            const [projeler, bolumler] = await Promise.all([projeResponse, bolumResponse]);

            

            setProjeler(projeler.data)
            setBolumler(bolumler.data)


          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        };
      
        fetchData();
      }, [])


      const handleChangeProje = (event) => {
        const selectedValue = event.target.value;
        const selectedItem = projeler.find((item) => item.proje_adi === selectedValue);
        const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
        setSeciliProjeID(selectedKey)
        setSeciliProje(selectedValue)
    
      };
    
      const handleChangeBolum = (event) => {
    
        const selectedValue = event.target.value;
        const selectedItem = bolumler.find((item) => item.bolum_no_text === selectedValue);
        const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
        setSeciliBolumID(selectedKey)
        setSeciliBolum(selectedValue)
      }

      const onClickListele = (event) => {

        if (seciliProje === '' || seciliBolum === '') {
            toast.error("Lütfen proje & bölüm seçiniz!", {
              position: toast.POSITION.BOTTOM_CENTER
            })
           }
     
         setLoading(true);
     
         axios.post("http://localhost:5001/api/get-butce", null, {params : {
           "seciliProje" : seciliProje,
           "seciliBolum" : seciliBolum
         }})
         .then(response => {
           setButce(response.data) 
           setLoading(false)
         })
         .catch(error => {
           console.warn(error);
           setLoading(false)
         })
         
         console.log(butce);
     
       }
      
    return (
        <div className="home">
            <Sidebar/>
            <div className="homeContainer">
                <Navbar/>
                <div className="selectSectionHome">
                <FormControl sx={{ m: 1, minWidth: 150 }} size="small" required>
                    <InputLabel id="demo-select-small-label">Proje</InputLabel>
                    <Select
                    required
                    value={seciliProje}
                    label="Proje"
                    onChange={handleChangeProje}>
                        {projeler.map((item, key) => (
                        <MenuItem key={item.id} value={item.proje_adi}>{item.proje_adi}</MenuItem>
                    ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 150 }} size="small" required>
                    <InputLabel id="demo-select-small-label">Bölüm</InputLabel>
                    <Select
                    required
                    value={seciliBolum}
                    label="Bolum"
                    onChange={handleChangeBolum}>
                        {bolumler.map((item, key) => (
                        <MenuItem key={item.id} value={item.bolum_no_text }>{item.bolum_no_text}</MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <div className="buttons">
                    <Button className='button listeleButton' variant="contained" onClick={onClickListele}>Listele</Button>
                </div>
                </div>
                <div className="widgets">
                    <Widget type="giderkalemsayisi" butce={butce} />
                    <Widget type="toplambutce" butce={butce}/>
                    <Widget type="toplamgerceklesen" butce={butce}/>
                    <Widget type="toplamfark" butce={butce}/>
                </div>
                <div className="listContainer">
                <div className="listTitle">Bar Grafiği</div>
                {loading ? (
                    <MutatingDots
                    height="100"
                    width="100"
                    color="#4e73df"
                    secondaryColor="#4e73df"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    />
                ) : butce.length > 0 ? ( // Kontrol etmek istediğiniz state "butce"
                    <BarChart
                    width={1000}
                    height={450}
                    data={butce}
                    margin={{
                        top: 30
                    }}
                    >
                    <XAxis dataKey="Ana_masraf_adi" stroke="#8884d8" />
                    <YAxis />
                    <Tooltip
                        wrapperStyle={{
                        width: 200,
                        backgroundColor: '#ccc'
                        }}
                    />
                    <Legend
                        width={100}
                        wrapperStyle={{
                        top: 40,
                        right: 20,
                        backgroundColor: '#f5f5f5',
                        border: '1px solid #d5d5d5',
                        borderRadius: 3,
                        lineHeight: '40px'
                        }}
                    />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Bar dataKey="butce" fill="#224abe" barSize={20} />
                    <Bar dataKey="odeme" fill="#82ca9d" barSize={20} />
                    </BarChart>
                ) : (
                    <div>Grafikleri görebilmek için lütfen proje ve bölüm seçiniz.</div>
                )}
                </div>
                <div className="listContainer">
                <div className="listTitle">Line Grafiği</div>
                {loading ? (
                    <MutatingDots
                    height="100"
                    width="100"
                    color="#4e73df"
                    secondaryColor="#4e73df"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    />
                ) : butce.length > 0 ? ( // Kontrol etmek istediğiniz state "butce"
                    <LineChart
                     width={1000}
                     height={450}
                     data={butce}
                     margin={{
                         top: 30
                     }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Kirilim_adi" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="gerceklesen" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="butce" stroke="#82ca9d" />
                    </LineChart>
                ) : (
                    <div>Grafikleri görebilmek için lütfen proje ve bölüm seçiniz.</div>
                )}
                </div>
            </div>
            <ToastContainer autoClose={1500}/>
        </div>
    )
}

export default Home