import React from 'react'
import './anamaliyetekle.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'

function AnaMaliyetEkle() {
  return (
    <div className="anaMaliyetEkle">
    <Sidebar/>
    <div className="anaMaliyetEkleContainer">
    <Navbar/>
    </div>
  </div>
  )
}

export default AnaMaliyetEkle