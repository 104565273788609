import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import './altmaliyetkirilimekle.scss'

function AltMaliyetKirilimEkle() {
  return (
    <div className="altMaliyetKirilimEkle">
    <Sidebar/>
    <div className="altMaliyetKirilimEkleContainer">
    <Navbar/>
    </div>
  </div>
  )
}

export default AltMaliyetKirilimEkle