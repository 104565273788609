import "./widget.scss"
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const Widget = ({type, butce}) => {


    let data;

    let tempGiderKalemSayisi = []
    let toplamButce = 0
    let toplamgerceklesen = 0
    let toplamfark = 0

      
      
    if (butce) {
      butce.forEach(element => {
        tempGiderKalemSayisi.push(element.Kirilim_Adi);
        toplamButce += parseFloat(element.butce) || 0;
        toplamgerceklesen += parseFloat(element.gerceklesen) || 0;
        toplamfark += parseFloat(element.fark) || 0;
      });
    }

    switch (type) {
        case "giderkalemsayisi":
          data = {
            title: "GİDER KALEM SAYISI",
            isMoney: false,
            amount: tempGiderKalemSayisi.length,
            icon: (
              <FormatListNumberedIcon
                className="icon"
                style={{
                  backgroundColor: "rgba(218, 165, 32, 0.2)",
                  color: "goldenrod",
                }}
              />
            ),
          };
          break;
        case "toplambutce":
          data = {
            title: "TOPLAM BÜTÇE",
            isMoney: true,
            amount: toplamButce,
            icon: (
              <CurrencyLiraIcon
                className="icon"
                style={{
                  backgroundColor: "rgba(128, 0, 128, 0.2)",
                  color: "purple",
                }}
              />
            ),
          };
          break;
        case "toplamgerceklesen":
          data = {
            title: "TOPLAM GERÇEKLEŞEN",
            isMoney: true,
            amount: toplamgerceklesen,
            icon: (
              <CurrencyLiraIcon
                className="icon"
                style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
              />
            ),
          };
          break;
        case "toplamfark":
          data = {
            title: "TOPLAM FARK",
            isMoney: true,
            amount: toplamfark,
            icon: (
              <CurrencyLiraIcon
                className="icon"
                style={{
                  backgroundColor: "rgba(255, 0, 0, 0.2)",
                  color: "crimson",
                }}
              />
            ),
          };
          break;
        default:
          break;
      }







  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
        {data.amount} {data.isMoney && "₺"}
        </span>
      </div>
      <div className="right">
        {data.icon}
      </div>
    </div>
  )
}

export default Widget