import "./navbar.scss"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

import { useContext } from "react";
import { AuthContext } from "../../context/authContext";

const Navbar = () => {

  const { currentUser } = useContext(AuthContext)

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon className="icon"/>
        </div>
        <div className="items">
          <div className="item">
            <LanguageOutlinedIcon className="icon"/>
            Türkçe
          </div>
          <div className="item">
            <DarkModeOutlinedIcon className="icon"/>
            
          </div>
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
            
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon"/>
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon"/>
            <div className="counter">2</div>
            
          </div>
          <div className="item">
            Hoşgeldiniz, {currentUser?.ad} {currentUser?.soyad}
            {/* <ListOutlinedIcon className="icon"/> */}
            
          </div>
          <div className="item">
            <img 
            src="https://images.pexels.com/photos/11293709/pexels-photo-11293709.jpeg?cs=srgb&dl=pexels-vietnam-photographer-11293709.jpg&fm=jpg" 
            alt=""
            className="avatar" />
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
