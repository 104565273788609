import React from 'react'
import { useState, useEffect } from 'react'
import "./farklarrapor.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { FormControl,Button, Select, MenuItem, InputLabel } from '@mui/material';
import axios from 'axios'
import 'webdatarocks/webdatarocks.css';
import * as WebDataRocksReact from 'react-webdatarocks';
import {MutatingDots} from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';

function FarklarRapor() {

    
  const [projeler, setProjeler] = useState([]);
  const [seciliProje, setSeciliProje] = useState('');
  const [seciliProjeID, setSeciliProjeID] = useState('')

  const [bolumler, setBolumler] = useState([])
  const [seciliBolum, setSeciliBolum] = useState('')
  const [seciliBolumID, setSeciliBolumID] = useState('')

  const [farklarRapor, setFarklarRapor] = useState([])
  const [loading, setLoading] = useState(false);

    useEffect(() => {
    fetchData()
  }, []) 

   const fetchData = async () => {
    try {
      const projeResponse = axios.get('http://localhost:5001/api/get-projeler');
      const bolumResponse = axios.get('http://localhost:5001/api/get-bolumler');
  
      const [projeler, bolumler] = await Promise.all([projeResponse, bolumResponse]);


      setProjeler(projeler.data)
      setBolumler(bolumler.data)

    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeProje = (event) => {
    const selectedValue = event.target.value;
    const selectedItem = projeler.find((item) => item.proje_adi === selectedValue);
    const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
    setSeciliProjeID(selectedKey)
    setSeciliProje(selectedValue)

  };

  const handleChangeBolum = (event) => {

    const selectedValue = event.target.value;
    const selectedItem = bolumler.find((item) => item.bolum_no_text === selectedValue);
    const selectedKey = selectedItem.bolum_no_int; // Seçilen projenin ID'si alınıyor.
    setSeciliBolumID(selectedKey)
    setSeciliBolum(selectedValue)
  }

  const onClickListele = (event) => {

    if (seciliProje === '' || seciliBolum === '') {
      toast.error("Lütfen proje & bölüm seçiniz!", {
        position: toast.POSITION.BOTTOM_CENTER
      })
     }
      setLoading(true);
        
      axios.get("http://localhost:5001/api/get-farklar-rapor", {params : {
        "seciliProjeID" : seciliProjeID,
        "seciliBolumID" : seciliBolumID
      }})
      .then(response => {
         
        if (response.data.length > 0) {
         const oStr = JSON.stringify(response.data);
         const oParsed = JSON.parse(oStr);
         setFarklarRapor(oParsed);
         console.log(oParsed);
       }
        setLoading(false)
      })
      .catch(error => {
        console.error(error);
        setLoading(false)
      })
 
   }

   const reportData = {
    dataSource: {
      data: farklarRapor
    },
    localization: "https://cdn.webdatarocks.com/loc/tr.json",
    options: {
      grid: {
        type: 'flat',
        showTotals: 'on',
        showGrandTotals: 'on',
      },
    },
    "formats": [{
      "name": "",
      "thousandsSeparator": ",",
      "decimalSeparator": ".",
      "currencySymbol": "₺",
      "currencySymbolAlign": "right",
      "nullValue": "",
      "textAlign": "center",
      "isPercent": false
    }
  ],
  "conditions": [
    {
        "formula": "#value < 0",
        "measure": "Fark",
        "format": {
            "backgroundColor": "#E57373",
            "color": "#000000",
            "fontFamily": "Arial",
            "fontSize": "14px"
        }
    },
    {
        "formula": "#value > 0",
        "measure": "Fark",
        "format": {
            "backgroundColor": "#9CCC65",
            "color": "#000000",
            "fontFamily": "Arial",
            "fontSize": "14px"
        }
    }
],
  }



  return (
   <div className='farklarRapor'>
    <Sidebar/>
    
    <div className="farklarRaporContainer">
        <Navbar/>
        <div className="selectSectionFarklarRapor">
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small" required>
            <InputLabel id="demo-select-small-label">Proje</InputLabel>
            <Select
              required
              labelId="demo-select-small-label"
              id="projeSec"
              value={seciliProje}
              label="Proje"
              onChange={handleChangeProje}>
                {projeler.map((item, key) => (
                <MenuItem key={item.id} value={item.proje_adi}>{item.proje_adi}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 150 }} size="small" required>
            <InputLabel id="demo-select-small-label">Bölüm</InputLabel>
            <Select
              required
              labelId="demo-select-small-label"
              id="bolumSec"
              value={seciliBolum}
              label="Bolum"
              onChange={handleChangeBolum}>
                {bolumler.map((item, key) => (
                <MenuItem key={item.bolum_no_int} value={item.bolum_no_text }>{item.bolum_no_text}</MenuItem>
              ))}
            </Select>
          </FormControl>
            <Button className='button listeleButton' variant="contained" onClick={onClickListele}>Listele</Button>
        </div>
        {loading ? (
            <MutatingDots 
            height="100"
            width="100"
            color="#4e73df"
            secondaryColor= '#4e73df'
            radius='12.5'
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />
      ) : (
          <div className="raporSection">
                 <WebDataRocksReact.Pivot 
                  toolbar={true}
                  width="100%"
                  report={reportData}
                  />
          </div>
 
        )}
    </div>
    <ToastContainer autoClose={1500}/>
    </div>
  )
}

export default FarklarRapor