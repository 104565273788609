import React from 'react'
import { useState, useEffect } from 'react'
import './odemeverigirisi.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { FormControl, FormLabel, TextField, Modal, Box, Button, Select, MenuItem, InputLabel, Grid } from '@mui/material';
import axios from 'axios'
import List from '../../components/table/Table'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function OdemeVeriGirisi() {

    const [projeler, setProjeler] = useState([]);
    const [seciliProje, setSeciliProje] = useState('');
    const [seciliProjeID, setSeciliProjeID] = useState('')

    const [bolumler, setBolumler] = useState([])
    const [seciliBolum, setSeciliBolum] = useState('')
    const [seciliBolumID, setSeciliBolumID] = useState('')

    const [butce, setButce] = useState([])
    
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData()
      }, []) 

    const fetchData = async () => {
        try {
          const projeResponse = axios.get('http://localhost:5001/api/get-projeler');
          const bolumResponse = axios.get('http://localhost:5001/api/get-bolumler');
      
          const [projeler, bolumler] = await Promise.all([projeResponse, bolumResponse]);
    
    
          setProjeler(projeler.data)
          setBolumler(bolumler.data)
    
        } catch (error) {
          console.error(error);
        }
      }  

    const handleChangeProje = (event) => {
        const selectedValue = event.target.value;
        const selectedItem = projeler.find((item) => item.proje_adi === selectedValue);
        const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
        setSeciliProjeID(selectedKey)
        setSeciliProje(selectedValue)
    
      };  

    const handleChangeBolum = (event) => {

        const selectedValue = event.target.value;
        const selectedItem = bolumler.find((item) => item.bolum_no_text === selectedValue);
        const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
        setSeciliBolumID(selectedKey)
        setSeciliBolum(selectedValue)
      }
      

      const onClickListele = (event) => {

        if (seciliProje === '' || seciliBolum === '') {
         toast.error("Lütfen proje & bölüm seçiniz!", {
           position: toast.POSITION.BOTTOM_CENTER
         })
        }
     
         setLoading(true);
        
         axios.post("http://localhost:5001/api/get-butce", null, {params : {
           "seciliProje" : seciliProje,
           "seciliBolum" : seciliBolum
         }})
         .then(response => {
           setButce(response.data) 
           setLoading(false)
         })
         .catch(error => {
           console.warn(error);
           setLoading(false)
         })
         
         console.log(butce);
     
       }
  return (
    <div className="odemeVeriGirisi">
        <Sidebar/>
        <div className="odemeVeriGirisiContainer">
        <Navbar/>
        <div className="selectSection">
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small" required>
                <InputLabel id="demo-select-small-label">Proje</InputLabel>
                <Select
                required
                labelId="demo-select-small-label"
                id="projeSec"
                value={seciliProje}
                label="Proje"
                onChange={handleChangeProje}>
                    {projeler.map((item, key) => (
                    <MenuItem key={item.id} value={item.proje_adi}>{item.proje_adi}</MenuItem>
                ))}
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 150 }} size="small" required>
                <InputLabel id="demo-select-small-label">Bölüm</InputLabel>
                <Select
                required
                labelId="demo-select-small-label"
                id="bolumSec"
                value={seciliBolum}
                label="Bolum"
                onChange={handleChangeBolum}>
                    {bolumler.map((item, key) => (
                    <MenuItem key={item.id} value={item.bolum_no_text }>{item.bolum_no_text}</MenuItem>
                ))}
                </Select>
            </FormControl>
            <div className="buttons">
                <Button className='button listeleButton' variant="contained" onClick={onClickListele}>Listele</Button>
            </div>
        </div>
        <div className="acordion">
            <List data={butce} columnType="odeme"/> 
            
            </div>   
        </div>
        <ToastContainer autoClose={1500}/>
    </div>
  )
}

export default OdemeVeriGirisi