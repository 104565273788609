import React from 'react'
import {useState, useEffect} from 'react'
import axios from 'axios'
import "./butcegenelrapor.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import 'webdatarocks/webdatarocks.css';
import * as WebDataRocksReact from 'react-webdatarocks';
import {MutatingDots} from 'react-loader-spinner'


function ButceGenelRapor() {

  const [rapor, setRapor] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('http://localhost:5001/api/get-butce-rapor');
        
        if (response.data.length > 0) {
          const oStr = JSON.stringify(response.data);
          const oParsed = JSON.parse(oStr);
          setRapor(oParsed);
          console.log(oParsed);
        }
        
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [])

  const reportData = {
    dataSource: {
      data: rapor
    },
    localization: "https://cdn.webdatarocks.com/loc/tr.json",
    options: {
      grid: {
        type: 'flat',
        showTotals: 'off',
        showGrandTotals: 'columns',
      },
    },
    "formats": [{
      "name": "",
      "thousandsSeparator": ",",
      "decimalSeparator": ".",
      "currencySymbol": "₺",
      "currencySymbolAlign": "right",
      "nullValue": "",
      "textAlign": "center",
      "isPercent": false
    },
  ],
  "conditions": [
  {
      "formula": "#value < 0",
      "measure": "butce",
      "format": {
          "backgroundColor": "#E57373",
          "color": "#000000",
          "fontFamily": "Arial",
          "fontSize": "14px"
      }
  },
  {
      "formula": "#value > 0",
      "measure": "butce",
      "format": {
          "backgroundColor": "#9CCC65",
          "color": "#000000",
          "fontFamily": "Arial",
          "fontSize": "14px"
      }
  }
],
  };

  return (
    <div className='butceGenelRapor'>
    <Sidebar/>
    
    <div className="butceGenelRaporContainer">
        <Navbar/>
        {loading ? (
            <MutatingDots 
            height="100"
            width="100"
            color="#4e73df"
            secondaryColor= '#4e73df'
            radius='12.5'
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />
      ) : (
          <div className="raporSection">
                 <WebDataRocksReact.Pivot 
                  toolbar={true}
                  width="100%"
                  report={reportData}
                  />
          </div>
 
        )}
    </div>
      
    </div>
  )
}

export default ButceGenelRapor