import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import './projetipiekle.scss'

function ProjeTipiEkle() {
  return (
    <div className="projeTipiEkle">
    <Sidebar/>
    <div className="projeTipiEkleContainer">
    <Navbar/>
    </div>
  </div>
  )
}

export default ProjeTipiEkle