import React, { useEffect, useState } from 'react'
import './projeekle.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { useFormik, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import { FormControl,FormControlLabel,RadioGroup, Radio,FormLabel, TextField, Modal, Box, Button, Select, MenuItem, InputLabel, Grid, DatePicker } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const validationSchema = Yup.object({
	firstName: Yup.string().required("Zorunlu alan"),
	lastName: Yup.string().required("Zorunlu alan"),
});


function ProjeEkle() {


  const [projeTipi, setProjeTipi] = useState([])
  const [selectedProjeTipi, setSelectedProjeTipi] = useState('')
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('http://localhost:5001/api/get-proje-tipi');
        console.log(response.data);
        setProjeTipi(response.data)
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [])

  const { handleSubmit, handleChange, values, errors } = useFormik({
		initialValues: {
			projeAdi: '',
			projeTipi: '',
		},
		validationSchema,
		onSubmit: values => {
			console.log(values);
		},
	});


  return (
    <div className="projeEkle">
      <Sidebar/>
      <div className="projeEkleContainer">
      <Navbar/>
      <div className="form">
   
              {/* <div className='button'>
                <Button onClick={onClickKaydet} variant="contained" color="success" endIcon={<DoneIcon />}>
                  Kaydet
                </Button>
              </div> */}
    </div>

       
    </div>
      
    </div>
  )
}

export default ProjeEkle