import {useState, useEffect} from 'react'
import axios from 'axios'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import List from '../../components/table/Table'
import "./butceislemleri.scss"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {MutatingDots} from 'react-loader-spinner'
import AddIcon from '@mui/icons-material/Add';
import { FormControl, FormLabel, TextField, Modal, Box, Button, Select, MenuItem, InputLabel, Grid } from '@mui/material';


function ButceIslemleri() {

  const [projeler, setProjeler] = useState([]);
  const [seciliProje, setSeciliProje] = useState('');
  const [seciliProjeID, setSeciliProjeID] = useState('')

  const [bolumler, setBolumler] = useState([])
  const [seciliBolum, setSeciliBolum] = useState('')
  const [seciliBolumID, setSeciliBolumID] = useState('')

  const [anaMaliyetler, setAnaMaliyetler] = useState([])
  const [seciliAnaMaliyet, setSeciliAnaMaliyet] = useState('')

  const [altMaliyetler, setAltMaliyetler] = useState([])
  const [altMaliyetKirilimlar, setAltMaliyetKirilimlar] = useState([])
  const [firmalar, setFirmalar] = useState([])
  const [dokumanTipleri, setDokumanTipleri] = useState([])
  
  const [butce, setButce] = useState([])

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false)

  // Form Verileri
  const [formData, setFormData] = useState({
    seciliProjeForm: '',
    seciliBolumForm: '',
    seciliAnaMaliyetForm: '',
    seciliAltMaliyetForm: '',
    seciliAltMaliyetKirilimForm: '',
    seciliAciklamaForm: '',
    seciliFirmaForm: '',
    seciliKarakterAdiForm: '',
    seciliDokumanTipiForm: '',
    seciliMiktarForm: '',
    seciliButceForm: '',
  });





  const handleFieldChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  useEffect(() => {
    fetchData()
  }, []) 

  const fetchData = async () => {
    try {
      const projeResponse = axios.get('http://localhost:5001/api/get-projeler');
      const bolumResponse = axios.get('http://localhost:5001/api/get-bolumler');
      const anaMaliyetResponse = axios.get('http://localhost:5001/api/get-ana-maliyetler');
      const altMaliyetResponse = axios.get('http://localhost:5001/api/get-alt-maliyetler');
      const altMaliyetKirilimResponse = axios.get('http://localhost:5001/api/get-alt-maliyet-kirilim');
      const firmaResponse = axios.get('http://localhost:5001/api/get-firma');
      const dokumanTipiResponse = axios.get('http://localhost:5001/api/get-dokuman-tipi');
  
      const [projeler, bolumler, anaMaliyetler, altMaliyetler, altMaliyetKirilimlar, firmalar, dokumanTipleri] = await Promise.all([projeResponse, bolumResponse, anaMaliyetResponse, altMaliyetResponse, altMaliyetKirilimResponse, firmaResponse, dokumanTipiResponse]);


      setProjeler(projeler.data)
      setBolumler(bolumler.data)
      setAnaMaliyetler(anaMaliyetler.data)
      setAltMaliyetler(altMaliyetler.data)
      setAltMaliyetKirilimlar(altMaliyetKirilimlar.data)
      setFirmalar(firmalar.data)
      setDokumanTipleri(dokumanTipleri.data)

    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeProje = (event) => {
    const selectedValue = event.target.value;
    const selectedItem = projeler.find((item) => item.proje_adi === selectedValue);
    const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
    setSeciliProjeID(selectedKey)
    setSeciliProje(selectedValue)
    formData.seciliProjeForm = selectedValue
  };

  const handleChangeBolum = (event) => {

    const selectedValue = event.target.value;
    const selectedItem = bolumler.find((item) => item.bolum_no_text === selectedValue);
    const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
    setSeciliBolumID(selectedKey)
    setSeciliBolum(selectedValue)
    formData.seciliBolumForm = selectedValue
  }

  const handleChangeAnaMaliyet = (event) => {

    const selectedValue = event.target.value;
    const selectedItem = anaMaliyetler.find((item) => item.Ana_masraf_adi === selectedValue);
    const selectedKey = selectedItem.id; // Seçilen projenin ID'si alınıyor.
    setSeciliAnaMaliyet(selectedValue)
  }

  const onClickListele = (event) => {

   if (seciliProje === '' || seciliBolum === '') {
    toast.error("Lütfen proje & bölüm seçiniz!", {
      position: toast.POSITION.BOTTOM_CENTER
    })
   }

    setLoading(true);

    axios.post("http://localhost:5001/api/get-butce", null, {params : {
      "seciliProje" : seciliProje,
      "seciliBolum" : seciliBolum
    }})
    .then(response => {
      setButce(response.data) 
      setLoading(false)
    })
    .catch(error => {
      console.warn(error);
      setLoading(false)
    })
    
    console.log(butce);

  }

  const onPressEkle = () => {
    setOpenModal(true)
  }

  const handleClose = () => setOpenModal(false)

  const onClickKaydet = () => {

    let postObj = {
      projeID: '',
      bolumID: '',
      anaMaliyetID: '',
      altMasrafID: '',
      altMasrafKirilimID: '',
      firmaID: '',
      dokumanTipiID: '',
      karakterAdi: '',
      miktar: 0,
      butce: 0,
      aciklama: '',
    }

    postObj.karakterAdi = formData.seciliKarakterAdiForm
    postObj.aciklama = formData.seciliAciklamaForm
    postObj.butce = parseInt(formData.seciliButceForm)
    postObj.miktar = parseInt(formData.seciliMiktarForm)


    projeler.forEach((item) => {
      if(item.proje_adi === formData.seciliProjeForm){
       postObj.projeID = item.id
      }
    })

    
    bolumler.forEach((item) => {
      if(item.bolum_no_text === formData.seciliBolumForm){
       postObj.bolumID = item.bolum_no_int
      }
    })
    
    anaMaliyetler.forEach((item) => {
      if(item.Ana_masraf_adi === formData.seciliAnaMaliyetForm){
       postObj.anaMaliyetID = item.id
      }
    })

    altMaliyetler.forEach((item) => {
      if(item.alt_maliyet_adi === formData.seciliAltMaliyetForm){
       postObj.altMasrafID = item.id
      }
    })

    altMaliyetKirilimlar.forEach((item) => {
      if(item.Kirilim_Adi === formData.seciliAltMaliyetKirilimForm){
       postObj.altMasrafKirilimID = item.id
      }
    })

    
    firmalar.forEach((item) => {
      if(item.firma_Adi === formData.seciliFirmaForm){
       postObj.firmaID = item.id
      }
    })

    dokumanTipleri.forEach((item) => {
      if(item.dokuman_tipi_adi === formData.seciliDokumanTipiForm){
       postObj.dokumanTipiID = item.id
      }
    })
    



    console.log(postObj);
  

    setLoading(true)
    
    axios.post('http://localhost:5001/api/post-butce-kalemi', postObj)
    .then(function (response) {
      console.log(response);

      setLoading(false)
      setOpenModal(false);

      // RESPONSE BAŞARILI DÖNERSE BAŞARILI MESAJI YAYIMLANSIN VE TABLO TEKRAR RENDER EDİLSİN.
      if (response.status === 200) {
        toast.success("Başarıyla kayıt atıldı!", {
          position: toast.POSITION.BOTTOM_CENTER
        })
       }
       onClickListele()
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false)
    });
    
    

  } 
    
  const handleFieldChangeAnaMaliyet = (e) => {

    let anaMaliyetID
    //Ana Maliyet değiştiğinde alt kırımlar değişecek.
    
    setFormData({
      ...formData,
      seciliAnaMaliyetForm: e.target.value,
    });

    

    for (let i = 0; i < anaMaliyetler.length; i++) {
      if (anaMaliyetler[i].Ana_masraf_adi === e.target.value) {
        anaMaliyetID = anaMaliyetler[i].id 
      }
    }

    const updatedAltMaliyetler = altMaliyetler.map((item) => {
      return {
        ...item,
        display: item.ana_maliyet_id === anaMaliyetID ? 'block' : 'none'
    }
    })

    const updatedAltMaliyetKirilimlar = altMaliyetKirilimlar.map((item) => {
      return {
        ...item,
        display: item.ana_maliyet_id === anaMaliyetID ? 'block' : 'none'
    }
    })

    setAltMaliyetler(updatedAltMaliyetler);
    setAltMaliyetKirilimlar(updatedAltMaliyetKirilimlar)

  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '16px',
    width: '60%',
    outline: 'none', // Modal dışında tıklamalarda kenarlık çıkmaması için
    border: '1px solid #000',
    borderRadius: '5px', // Modal'in köşelerini yuvarlamak için
    boxShadow: '0px 13px 100px rgba(0, 0, 0, 0.6)', 
  };

  const textFieldStyle = {
    marginBottom: '10px', // TextField'ları birbirinden ayıran boşluk
  };

  return (

    <div className='butceIslemleri'>
        <Sidebar/>
        
        <div className="butceContainer">
            <Navbar/>
        <div>
          <div className="selectSectionButceIslemleri">
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small" required>
            <InputLabel id="demo-select-small-label">Proje</InputLabel>
            <Select
              required
              labelId="demo-select-small-label"
              id="projeSec"
              value={seciliProje}
              label="Proje"
              onChange={handleChangeProje}>
                {projeler.map((item, key) => (
                <MenuItem key={item.id} value={item.proje_adi}>{item.proje_adi}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 150 }} size="small" required>
            <InputLabel id="demo-select-small-label">Bölüm</InputLabel>
            <Select
              required
              labelId="demo-select-small-label"
              id="bolumSec"
              value={seciliBolum}
              label="Bolum"
              onChange={handleChangeBolum}>
                {bolumler.map((item, key) => (
                <MenuItem key={item.bolum_no_int} value={item.bolum_no_text }>{item.bolum_no_text}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="buttons">
            <Button className='button listeleButton' variant="contained" onClick={onClickListele}>Listele</Button>
            <Button className='button ekleButton' color="success" variant="contained" onClick={onPressEkle}><AddIcon></AddIcon></Button>
          </div>
          </div>
          <div className="acordion">
           <List data={butce} columnType="butce"/> 
         
          </div>



         <Modal open={openModal} onClose={handleClose}>
            <div style={modalStyle}>
              <Grid container spacing={2}>
                {/* İlk sütun */}
                <Grid item xs={6}>
                    <InputLabel id="projeSec">Proje Seç</InputLabel>
                    <Select
                    size="small"
                    fullWidth 
                    style={textFieldStyle}
                    labelId="demo-select-small-label"
                    id="projeSec"
                    value={formData.seciliProjeForm}
                    onChange={(e) => handleFieldChange('seciliProjeForm', e.target.value)}>
                      {projeler.map((item, key) => (
                      <MenuItem key={item.id} value={item.proje_adi}>{item.proje_adi}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="bolumSec">Bölüm Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="bolumSec"
                    value={formData.seciliBolumForm}
                    onChange={(e) => handleFieldChange('seciliBolumForm', e.target.value)}>
                      {bolumler.map((item, key) => (
                      <MenuItem key={item.id} value={item.bolum_no_text}>{item.bolum_no_text}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="anaMaliyetSec">Ana Maliyet Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="anaMaliyetSec"
                    value={formData.seciliAnaMaliyetForm}
                    onChange={handleFieldChangeAnaMaliyet}>
                      {anaMaliyetler.map((item, key) => (
                      <MenuItem key={item.id} value={item.Ana_masraf_adi}>{item.Ana_masraf_adi}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="altMaliyetSec">Alt Maliyet Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="altMaliyetSec"
                    value={formData.seciliAltMaliyetForm}
                    onChange={(e) => handleFieldChange('seciliAltMaliyetForm', e.target.value)}>
                      {altMaliyetler.map((item, key) => (
                      <MenuItem style={{ display: item.display }} key={item.id} value={item.alt_maliyet_adi}>{item.alt_maliyet_adi}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="altMaliyetKirilimSec">Alt Maliyet Kırılım Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="altMaliyetKirilimSec"
                    value={formData.seciliAltMaliyetKirilimForm}
                    onChange={(e) => handleFieldChange('seciliAltMaliyetKirilimForm', e.target.value)}>
                      {altMaliyetKirilimlar.map((item, key) => (
                      <MenuItem style={{ display: item.display }} key={item.id} value={item.Kirilim_Adi}>{item.Kirilim_Adi}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel id="firmaSec">Firma Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="firmaSec"
                    value={formData.seciliFirmaForm}
                    onChange={(e) => handleFieldChange('seciliFirmaForm', e.target.value)}>
                      {firmalar.map((item, key) => (
                      <MenuItem key={item.id} value={item.firma_Adi}>{item.firma_Adi}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* İkinci sütun */}
                <Grid item xs={6}>
                  <InputLabel id="dokumanTipiSec">Döküman Tipi Seç</InputLabel>
                  <Select
                  size="small"
                    fullWidth 
                    style={textFieldStyle}
                    id="dokumanTipiSec"
                    value={formData.seciliDokumanTipiForm}
                    onChange={(e) => handleFieldChange('seciliDokumanTipiForm', e.target.value)}>
                      {dokumanTipleri.map((item, key) => (
                      <MenuItem key={item.id} value={item.dokuman_tipi_adi}>{item.dokuman_tipi_adi}</MenuItem>
                    ))}
                  </Select>
                  <InputLabel  id="karakterAdi">Karakter Adı</InputLabel>
                  <TextField onChange={(e) => handleFieldChange('seciliKarakterAdiForm', e.target.value)} size="small" fullWidth style={textFieldStyle} />
                  <InputLabel id="miktar">Miktar</InputLabel>
                  <TextField onChange={(e) => handleFieldChange('seciliMiktarForm', e.target.value)} size="small" type="number"  fullWidth style={textFieldStyle} />
                  <InputLabel id="butce">Bütçe (₺)</InputLabel>
                  <TextField onChange={(e) => handleFieldChange('seciliButceForm', e.target.value)} size="small" type="number" fullWidth style={textFieldStyle} />
                  <InputLabel id="aciklama">Açıklama</InputLabel>
                  <TextField onChange={(e) => handleFieldChange('seciliAciklamaForm', e.target.value)} size="small" fullWidth style={textFieldStyle} />
                </Grid>
              </Grid>

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button onClick={onClickKaydet} variant="contained" color="success">
                  Kaydet
                </Button>
              </div>
            </div>
         </Modal>
        </div> 
        </div>
        <ToastContainer autoClose={1500}/>
        
    </div>
  )
}

export default ButceIslemleri